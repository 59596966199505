import Vue from 'vue';
import AccountApiProxyService from '@/services/AccountApiProxyService';
import { AccountConstants } from '@/constants';

const queryString = require('query-string');

export default new Vue({
  methods: {
    getUsersMetrics() {
      return AccountApiProxyService.get(
        AccountConstants.ACCOUNT_API_PATH + `/users/metrics`
      );
    },

    getQueryString(pagination, query) {
      return queryString.stringify({
        page: (pagination.page || 1) - 1,
        size: pagination.rowsPerPage,
        sort: [pagination.sortBy, pagination.descending ? 'desc' : 'asc'].join(
          ','
        ),
        query: query,
      });
    },
    getUsers(pagination) {
      return AccountApiProxyService.get(
        AccountConstants.ACCOUNT_API_PATH +
          '/users/all?' +
          this.getQueryString(pagination)
      );
    },
    getUsersBasic() {
      return AccountApiProxyService.get(
        AccountConstants.ACCOUNT_API_PATH + '/users/basic'
      );
    },
    getUsersBasicPrimary() {
      return AccountApiProxyService.get(
        AccountConstants.ACCOUNT_API_PATH + '/v2/users/basic/primary'
      );
    },
    resendEmailInvite(userId) {
      return AccountApiProxyService.post(
        AccountConstants.ACCOUNT_API_PATH + '/users/sendEmailInvite/' + userId,
        {}
      );
    },
    searchUsers(pagination, query) {
      return AccountApiProxyService.get(
        AccountConstants.ACCOUNT_API_PATH +
          '/users/searchNameAndEmail?' +
          this.getQueryString(pagination, query)
      );
    },
    searchUsersV2(query) {
      return AccountApiProxyService.get(
        AccountConstants.ACCOUNT_API_PATH + '/users/searchNameAndEmail?' + query
      );
    },
    doesUserEmailExist(email) {
      return AccountApiProxyService.get(
        AccountConstants.ACCOUNT_API_PATH + '/users/exists/email/' + email
      );
    },
    doesUserEmployeeIdExist(employeeId) {
      return AccountApiProxyService.get(
        AccountConstants.ACCOUNT_API_PATH +
          '/users/exists/employeeId/' +
          employeeId
      );
    },
    createUser(userData) {
      return AccountApiProxyService.post(
        AccountConstants.ACCOUNT_API_PATH + '/users',
        userData
      );
    },
    createBulkUsers(userData) {
      return AccountApiProxyService.post(
        AccountConstants.ACCOUNT_API_PATH + '/users/bulk',
        userData
      );
    },
    editUser(userData) {
      let patchUserData = {
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
        language: userData.language,
        employeeId: userData.employeeId,
        primaryPhone: userData.primaryPhone,
        primaryPhoneCountryCode: userData.primaryPhoneCountryCode,
        reimbursableLocations: userData.reimbursableLocations,
        segmentIds: userData.segmentIds,
        driver: userData.driver,
        driverPin: userData.driverPin,
      };

      if (userData['add-roles'].length > 0) {
        patchUserData['add-roles'] = userData['add-roles'];
      }

      if (userData['remove-roles'].length > 0) {
        patchUserData['remove-roles'] = userData['remove-roles'];
      }

      return AccountApiProxyService.patch(
        AccountConstants.ACCOUNT_API_PATH + '/users/' + userData.userId,
        patchUserData
      );
    },

    editUserSettings(userData) {
      let patchUserSettingsData = {
        firstName: userData.firstName,
        lastName: userData.lastName,
        language: userData.language,
        employeeId: userData.employeeId,
        primaryPhone: userData.primaryPhone,
        billingInformation: userData.billingInformation,
        primaryPhoneCountryCode: userData.primaryPhoneCountryCode,
        notificationPreferences: userData.notificationPreferences,
      };

      return AccountApiProxyService.patch(
        AccountConstants.ACCOUNT_API_PATH +
          '/users/' +
          userData.userId +
          '/settings',
        patchUserSettingsData
      );
    },
    deleteUser(userId) {
      return AccountApiProxyService.delete(
        AccountConstants.ACCOUNT_API_PATH + '/users/' + userId
      );
    },
    getChangePasswordUrl() {
      return AccountApiProxyService.get(
        AccountConstants.ACCOUNT_API_PATH + '/users/change-password-url'
      );
    },
    generateDriverPin() {
      return AccountApiProxyService.get(
        AccountConstants.ACCOUNT_API_PATH + '/users/driver/new-pin'
      );
    },
    doesDriverPinExist(driverPin) {
      return AccountApiProxyService.get(
        AccountConstants.ACCOUNT_API_PATH +
          '/users/exists/driver-pin/' +
          driverPin
      );
    },
    getReimbursableAddress(userId) {
      return AccountApiProxyService.get(
        AccountConstants.ACCOUNT_API_PATH +
          '/users/reimbursableAddress/' +
          userId
      );
    },
    getUserAccessFilters() {
      return AccountApiProxyService.get(
        AccountConstants.ACCOUNT_API_PATH + '/users/filters'
      );
    },
  },
});
