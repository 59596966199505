import AccountApiProxyService from '@/services/AccountApiProxyService';
import { cloneDeep } from 'lodash';
import store from '@/store';
import { AccountConstants } from '@/constants';

const queryString = require('query-string');
const filterNames = [
  'makes',
  'models',
  'groups',
  'years',
  'products',
  'fuelTypes',
  'consentedConsumers',
  'hardwareCategories',
  'licensePlateStates',
  'externalFilterDisplay',
];

export default {
  extractFilterValues(filters) {
    let blankString = 'vehicle.filter.blankValue';
    let nonConsentedString = 'vehicle.readyForConsent';
    if (typeof filters === 'undefined') {
      return {};
    }
    let localFilter = cloneDeep(filters);
    for (let i = 0; i < filterNames.length; i++) {
      let filter = localFilter[filterNames[i]];
      if (filter) {
        for (let j = 0; j < filter.length; j++) {
          let value = filter[j];
          if (value === blankString || value === nonConsentedString) {
            filter[j] = null;
            break;
          }
        }
        localFilter[filterNames[i]] = filter;
      }
    }
    return localFilter;
  },

  getBody(filters, searchQuery) {
    let localFilters = {};
    if (filters) {
      localFilters = this.extractFilterValues(filters);
    }
    if (searchQuery) {
      localFilters['vehicleSearch'] = searchQuery;
    }
    return localFilters;
  },

  getQueryString(pagination, filters = null, searchQuery = null) {
    let localFilters;
    if (filters || searchQuery) {
      localFilters = JSON.stringify(this.getBody(filters, searchQuery));
    }

    return queryString.stringify({
      page: (pagination.page || 1) - 1,
      size: pagination.rowsPerPage,
      sort: [pagination.sortBy, pagination.descending ? 'desc' : 'asc'].join(
        ','
      ),
      ...(localFilters ? { filter: localFilters } : {}),
    });
  },
  getQueryStringForGroup(pagination, filters = null, searchQuery = null) {
    let localFilters = {};
    if (filters) {
      localFilters = this.extractFilterValues(filters);
    }
    if (searchQuery) {
      localFilters['vehicleSearch'] = searchQuery;
    }

    return queryString.stringify({
      ...pagination,
      ...(filters || searchQuery
        ? { filter: JSON.stringify(localFilters) }
        : {}),
    });
  },
  getVehicle(vehicleId) {
    return AccountApiProxyService.get(
      AccountConstants.ACCOUNT_API_PATH +
        '/vehicles/' +
        vehicleId +
        '?filter-hidden-products=true'
    );
  },
  downloadVehiclesCsv() {
    return AccountApiProxyService.get(
      AccountConstants.ACCOUNT_API_PATH + '/vehicles/export-to-csv'
    );
  },
  downloadVehiclesCsvWithFilter(filters, searchQuery) {
    return AccountApiProxyService.post(
      AccountConstants.ACCOUNT_API_PATH + '/vehicles/export-to-csv',
      this.getBody(filters, searchQuery)
    );
  },
  downloadOwnerVehiclesCsv() {
    return AccountApiProxyService.get(
      AccountConstants.ACCOUNT_API_PATH + '/vehicles/owner/export-to-csv'
    );
  },
  getBasicQueryString(filters, searchQuery) {
    let localFilters = this.extractFilterValues(filters);

    localFilters['vehicleSearch'] = searchQuery;
    return queryString.stringify({
      filter: JSON.stringify(localFilters),
    });
  },
  getConsentedVehicles(pagination, filters, searchQuery) {
    return AccountApiProxyService.post(
      AccountConstants.ACCOUNT_API_PATH +
        '/v2/vehicles/consented?' +
        this.getQueryStringForGroup(pagination),
      this.getBody(filters, searchQuery)
    );
  },
  getConsentedVehiclesRouter(pagination, filters, searchQuery) {
    return this.getConsentedVehicles(pagination, filters, searchQuery);
  },
  getConsentedVehiclesForGroup(pagination, filters, searchQuery) {
    return AccountApiProxyService.get(
      AccountConstants.ACCOUNT_API_PATH +
        '/vehicles/consented?' +
        this.getQueryStringForGroup(pagination, filters, searchQuery)
    );
  },
  getOwnedVehiclesForDataGrid(pagination, filters, searchQuery) {
    return AccountApiProxyService.get(
      AccountConstants.ACCOUNT_API_PATH +
        '/vehicles?' +
        this.getBasicQueryString(filters, searchQuery) +
        '&' +
        queryString.stringify(pagination)
    );
  },
  getOwnedVehicles(pagination, filters, searchQuery) {
    let newFilters = cloneDeep(filters);
    return AccountApiProxyService.get(
      AccountConstants.ACCOUNT_API_PATH +
        '/vehicles?' +
        this.getQueryString(pagination, newFilters, searchQuery)
    );
  },
  getVehicleFilters(verified) {
    if (verified == null) {
      verified = true;
    }
    return AccountApiProxyService.get(
      AccountConstants.ACCOUNT_API_PATH +
        '/vehicles/filters?verified=' +
        verified
    );
  },
  getConsentedVehicleFilters(verified) {
    if (verified == null) {
      verified = true;
    }

    return AccountApiProxyService.get(
      '/pro/dm-vehicle-api/v1/info/consented/filter-options?verified=' +
        verified
    );
  },
  // Remove function below after switching to microservices
  getConsentedVehicleFiltersOld(verified) {
    if (verified == null) {
      verified = true;
    }
    return AccountApiProxyService.get(
      AccountConstants.ACCOUNT_API_PATH +
        '/vehicles/consented/filters?verified=' +
        verified
    );
  },
  getDisabledVehicles(pagination, filters, searchQuery) {
    return AccountApiProxyService.get(
      AccountConstants.ACCOUNT_API_PATH +
        '/vehicles/disabled?' +
        this.getBasicQueryString(filters, searchQuery) +
        '&' +
        pagination
    );
  },
  getBasicConsentedVehicles(filters, searchQuery) {
    return AccountApiProxyService.get(
      AccountConstants.ACCOUNT_API_PATH +
        '/vehicles/consented/basic?' +
        this.getBasicQueryString(filters, searchQuery)
    );
  },
  getBasicOwnedVehicles(filters, searchQuery) {
    return AccountApiProxyService.get(
      AccountConstants.ACCOUNT_API_PATH +
        '/vehicles/basic?' +
        this.getBasicQueryString(filters, searchQuery)
    );
  },
  disableVehicle(vehicleId) {
    return AccountApiProxyService.delete(
      AccountConstants.ACCOUNT_API_PATH + '/vehicles/' + vehicleId + '/disable'
    );
  },
  enableVehicle(vehicleIds) {
    return AccountApiProxyService.post(
      AccountConstants.ACCOUNT_API_PATH + '/vehicles/enable',
      vehicleIds
    );
  },
  deleteVehicles(vehicleIds) {
    return AccountApiProxyService.delete(
      AccountConstants.ACCOUNT_API_PATH + '/vehicles/bulk',
      { data: vehicleIds }
    );
  },
  disableVehicleV2(vehicleId, data) {
    if (data === undefined) {
      return AccountApiProxyService.post(
        AccountConstants.ACCOUNT_API_PATH + '/v2/vehicles/disable',
        {
          vehicleId: vehicleId,
        }
      );
    } else {
      return AccountApiProxyService.post(
        AccountConstants.ACCOUNT_API_PATH + '/v2/vehicles/disable',
        {
          vehicleId: vehicleId,
          churnReasonId: data.churnId,
          additionalReason: data.reason,
        }
      );
    }
  },
  bulkDisableVehiclesV2(vehicles, data) {
    if (data === undefined) {
      return AccountApiProxyService.post(
        AccountConstants.ACCOUNT_API_PATH + '/v2/vehicles/disable/bulk',
        {
          vehicleIds: vehicles,
        }
      );
    } else {
      return AccountApiProxyService.post(
        AccountConstants.ACCOUNT_API_PATH + '/v2/vehicles/disable/bulk',
        {
          vehicleIds: vehicles,
          churnReasonId: data.churnId,
          additionalReason: data.reason,
        }
      );
    }
  },
  editVehicle(vehicleEditDetails) {
    return AccountApiProxyService.patch(
      AccountConstants.ACCOUNT_API_PATH +
        '/vehicles/' +
        vehicleEditDetails.vehicleId,
      {
        name: vehicleEditDetails.name,
        licensePlate: vehicleEditDetails.licensePlate,
        licensePlateExpirationDate:
          vehicleEditDetails.formattedLicensePlateExpirationDate,
        licensePlateState: vehicleEditDetails.licensePlateState,
      }
    );
  },
  bulkDisableVehicles(vehicleIds) {
    return AccountApiProxyService.post(
      AccountConstants.ACCOUNT_API_PATH + '/vehicles/disable/bulk',
      vehicleIds
    );
  },
  getOwnedAggregates(pagination, filters, searchQuery) {
    let newFilters = cloneDeep(filters);
    return AccountApiProxyService.get(
      AccountConstants.ACCOUNT_API_PATH +
        '/vehicles/aggregates?' +
        this.getBasicQueryString(newFilters, searchQuery) +
        '&' +
        queryString.stringify(pagination)
    );
  },
  getEnrollmentAggregatesV2(pagination, filters, searchQuery) {
    let newFilters = cloneDeep(filters);
    return AccountApiProxyService.post(
      AccountConstants.ACCOUNT_API_PATH + '/v2/vehicles/aggregates/enrollment',
      this.getBody(newFilters, searchQuery)
    );
  },
  checkVinExists(vin) {
    return AccountApiProxyService.get(
      AccountConstants.ACCOUNT_API_PATH + '/vehicles/' + vin + '/exists'
    );
  },
  createVehicle(vehicleDetails) {
    return AccountApiProxyService.post(
      AccountConstants.ACCOUNT_API_PATH + '/vehicles',
      vehicleDetails
    );
  },
  bulkVehicleUpload(vehiclesFile) {
    let formData = new FormData();
    formData.append('vehicleFile', vehiclesFile);
    return AccountApiProxyService.post(
      AccountConstants.ACCOUNT_API_PATH + '/vehicles/bulk?detailed=false',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  },
  bulkModifyVehicle(vehiclesFile) {
    let formData = new FormData();
    formData.append('vehicleFile', vehiclesFile);
    return AccountApiProxyService.post(
      AccountConstants.ACCOUNT_API_PATH + '/vehicles/bulk/update?detailed=true',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  },
  getBulkModifyVehicleCsvTemplate(vehicleIds) {
    return AccountApiProxyService.post(
      AccountConstants.ACCOUNT_API_PATH + '/vehicles/update/bulk/template',
      vehicleIds
    );
  },
  getBulkModifyVehicleExcelTemplate(vehicleIds) {
    return AccountApiProxyService.post(
      AccountConstants.ACCOUNT_API_PATH +
        '/vehicles/update/bulk/template/excel',
      vehicleIds
    );
  },
  getEnrollmentStatus(productSku, vehicles) {
    return this.getEnrollmentStatuses([productSku], vehicles);
  },
  getEnrollmentStatuses(productSkus, vehicles) {
    return AccountApiProxyService.post(
      AccountConstants.ACCOUNT_API_PATH +
        '/vehicles/status/capability?filterHiddenProducts=true',
      { productSku: productSkus, vehicleIds: vehicles }
    );
  },
  subscribeVehiclesToProduct(productSku, vehicleIds) {
    return AccountApiProxyService.post(
      AccountConstants.ACCOUNT_API_PATH + '/enrollment/enroll',
      {
        productSku,
        vehicleIds,
      }
    );
  },
  subscribeVehiclesToProductV2(productSku, vehicleIds) {
    return AccountApiProxyService.post(
      AccountConstants.ACCOUNT_API_PATH + '/v2/enrollment/enroll',
      {
        productSku,
        vehicleIds,
      }
    );
  },
  clearEnrollmentFailedStatus(productSku, vin) {
    return AccountApiProxyService.post(
      '/pro/dm-vehicle-api/v1/enrollment/product/clear',
      {
        productSku: productSku,
        vin: vin,
      }
    );
  },
  getVehicleEnrolledProducts(vehicles) {
    return AccountApiProxyService.post(
      AccountConstants.ACCOUNT_API_PATH +
        '/vehicles/product/enrolledProducts?filterHiddenProducts=true',
      vehicles
    );
  },
  unenrollVehicleProductCheck(product, vehicles) {
    return AccountApiProxyService.post(
      AccountConstants.ACCOUNT_API_PATH + '/vehicles/product/subscription',
      {
        vehicleIds: vehicles,
        productSku: product,
      }
    );
  },
  unenrollVehicleProduct(product, vehicles, churnReasonId, additionalReason) {
    return AccountApiProxyService.post(
      AccountConstants.ACCOUNT_API_PATH + '/vehicles/product/remove',
      {
        vehicleIds: vehicles,
        productSku: product,
        churnReasonId: churnReasonId,
        additionalReason: additionalReason,
      }
    );
  },

  unenrollVehicles(vehicles) {
    return AccountApiProxyService.post(
      AccountConstants.ACCOUNT_API_PATH + '/vehicles/product/remove-all',
      {
        vehicleIds: vehicles,
      }
    );
  },

  getEnrollments(productSkus) {
    return AccountApiProxyService.post(
      '/pro/dm-vehicle-api/v1/enrollment/counts',
      productSkus
    );
  },

  getEnrollmentsV2(productSkus) {
    return AccountApiProxyService.post(
      '/pro/dm-vehicle-api/v2/enrollment/counts',
      productSkus
    );
  },
  getVehicleIdsByStatus(productSku, status) {
    return AccountApiProxyService.post(
      '/pro/dm-vehicle-api/v1/enrollment/ids',
      { productSku: productSku, enrollmentState: status }
    );
  },
  getVehicleProducts(vehicleId) {
    return AccountApiProxyService.get(
      AccountConstants.ACCOUNT_API_PATH + `/vehicles/${vehicleId}/products`
    );
  },
  getEnrollables(productSku, productFamilySkus) {
    return AccountApiProxyService.post(
      AccountConstants.ACCOUNT_API_PATH + '/vehicles/enrollables',
      {
        productSku,
        productFamilySkus,
      }
    );
  },
  attemptUnsubscribe(product, vehicleKeys, churnReasonId, additionalReason) {
    return this.unenrollVehicleProductCheck(product.id, vehicleKeys).then(
      (res) => {
        let badVehicles = res.data.notEnrolledVehicles;
        let goodVehicles = res.data.enrolledVehicles;

        if (goodVehicles.length > 0 && badVehicles.length === 0) {
          return this.unenrollVehicleProduct(
            product.id,
            goodVehicles.map((v) => v.vehicle.vehicleId),
            churnReasonId,
            additionalReason
          );
        } else {
          return Promise.resolve({
            conflict: true,
            selectedProduct: product,
            goodVehicles: goodVehicles,
            badVehicles: badVehicles,
          });
        }
      }
    );
  },
  attemptMarkForDelete(vehicleKeys) {
    return AccountApiProxyService.post(
      AccountConstants.ACCOUNT_API_PATH + '/enrollment/remove/validate',
      vehicleKeys
    );
  },
  getCcsSettings(vins) {
    return AccountApiProxyService.post(
      AccountConstants.ACCOUNT_API_PATH + '/enrollment/ccs/status',
      vins
    );
  },
  getVehicleIdsCapableOfAndNotEnrolledOrInProgressForAProduct(productSku) {
    return AccountApiProxyService.get(
      AccountConstants.ACCOUNT_API_PATH +
        '/vehicles/enrollment/capable-not-enrolled',
      {
        params: {
          productSku,
        },
      }
    );
  },
  getVehicleEnrollmentAndCapabilityStatus(upgradableProduct, upgradedProduct) {
    return AccountApiProxyService.get(
      AccountConstants.ACCOUNT_API_PATH +
        '/vehicles/enrollment/capable-of-upgrade',
      {
        params: {
          upgradableProduct,
          upgradedProduct,
        },
      }
    );
  },
  extractQueryStringToStore(queryStringParam) {
    let json = JSON.parse(queryStringParam);
    let keys = Object.keys(json);
    for (let key of Object.keys(keys)) {
      let keyName = keys[key];
      let value = json[keyName];
      if (keyName === 'vehicleSearch') {
        store.dispatch('vehicle/setSearchQuery', { data: value });
      } else {
        store.dispatch('vehicle/setVehicleFilter', {
          name: keyName,
          selected: value,
        });
      }
    }
  },
};
